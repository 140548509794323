
import { defineComponent, reactive, computed } from 'vue'
import AuthSubmitButton from '@/components/particles/AuthLayout/AuthSubmitButton.vue'
import Logo from '@/components/particles/common/Logo.vue'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
} from 'vee-validate'
import { useI18n } from 'vue-i18n'
import useStore from '@/store'
import { useCancelToken } from '@/hooks/useCancelToken'
import { AUTH_ACTION_TYPES } from '@/store/auth/actions'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default defineComponent({
  components: {
    AuthSubmitButton,
    Logo,
  },

  emits: ['update-component'],
  setup(props, { emit }) {
    const getPersonalInfoCancelToken = useCancelToken()
    const { t } = useI18n()
    const store = useStore()
    const { handleSubmit } = useForm({
      validationSchema: {
        email: 'required|email',
      },
    })

    const form = reactive({
      email: useField('email', '', {
        label: t('LOGIN_FORM_LOGIN_LABEL'),
        validateOnValueUpdate: true,
      }),
    })

    const isError = computed(() => {
      if (store.state.userSettings.isError) {
        createToast({
          title: 'Erreur '+store.state.userSettings.error.status,
          description: store.state.userSettings.error.message
        },
          {
            type: 'danger',
            position: 'bottom-right',
            showIcon: true,
          })
         store.dispatch(
          AUTH_ACTION_TYPES.RESET_ERROR_STATE,
          getPersonalInfoCancelToken.value
        )
      }
      return store.state.userSettings.isError
    })

    const onSubmit = handleSubmit(async ({ email }) => {
      await store.dispatch(AUTH_ACTION_TYPES.SEND_VALIDATION_CODE, { email })
      createToast({
          title: 'Email envoyé'
        },
          {
            type: 'success',
            position: 'bottom-right',
            showIcon: true,
          })
    })

    return {
      form,
      onSubmit,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
      isError
    }
  },
})
