import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2.5 text-center font-medium text-sm" }
const _hoisted_2 = { class: "mb-[30px] text-base text-center" }
const _hoisted_3 = { class: "mx-auto max-w-[224px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_UiInputText = _resolveComponent("UiInputText")!
  const _component_AuthSubmitButton = _resolveComponent("AuthSubmitButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
    class: "max-w-[398px] max-h-md w-full pt-10 px-5 pb-8 bg-white rounded-lg"
  }, [
    _createVNode(_component_Logo, { class: "mb-[30px]" }),
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_vShow, _ctx.isError]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_TITLE')), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('FORGET_PASSWORD_INSERT_EMAIL')), 1)
    ]),
    _createVNode(_component_UiInputText, {
      modelValue: _ctx.form.email.value.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email.value.value) = $event)),
      errorMessage: _ctx.form.email.errorMessage.value,
      label: _ctx.form.email.label,
      type: "email",
      autocomplete: "email",
      class: "mb-4"
    }, null, 8, ["modelValue", "errorMessage", "label"]),
    _createVNode(_component_AuthSubmitButton, {
      class: "mb-2.5",
      loading: _ctx.isSubmitting,
      disabled: !_ctx.isValid
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_SUBMIT_BUTTON')), 1)
      ]),
      _: 1
    }, 8, ["loading", "disabled"]),
    _createVNode(_component_router_link, { to: { name: 'AuthorizationView' } }, {
      default: _withCtx(() => [
        _createVNode(_component_UiButton, { class: "py-4 bg-lightGrey text-darkGrey w-full text-sm hover:text-red" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_RETURN_BUTTON')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}